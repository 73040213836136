export const errors = {
  methods: {
    displayErrors(errors) {
      console.log(typeof errors)
      if (typeof errors == 'string') {
        return errors
      }
      if (Array.from(['object', 'array']).includes(typeof errors)) {
        let html = ''
        for (let error of errors) {
          html += `${ error }<br/>`
        }
        html += ''
        return html
      }
    }
  }
}