<template>
  <!-- TESTING TOASTS -->
  <div class="my-5 p-5" v-if="test">
    <button class="btn btn-primary btn-lg my-5" role="button" @click="addToast">
      Add Toast ( {{ counter }})
    </button>

    <PreDebug
      class="bg-primary bg-opacity-75"
      title="currentToastList"
      :show="true"
    >
      {{ currentToastList }}
    </PreDebug>
  </div>

  <TransitionGroup
    style="z-index: 999"
    name="slide-fade"
    tag="div"
    class="position-fixed top-0 end-0 p-3 mt-5"
    aria-live="polite"
    aria-atomic="true"
  >
    <!-- :style="{ 'animation-delay': index * 500 + 'ms' }" -->
    <Toast
      v-for="(toast, index) in currentToastList"
      :key="toast.time"
      @close="removeToast(toast)"
      :style="{ 'transition-delay': index * 100 + 'ms' }"
      :toast="toast"
    >
      <div v-html="toast.message" />
    </Toast>
  </TransitionGroup>
</template>

<script>
import Toast from "@/Components/Toasts/Toast.vue";

export default {
  components: {
    Toast,
  },
  props: {
    test: {
      type: Boolean,
      default: false,
    },

    toastList: {
      type: Array,
      default() {
        return [
          {
            message: "test toaster 1",
            class: "bg-primary",
          },
          {
            message: "test toaster 5 autohide",
            class: "bg-success",
            options: { delay: 2500, autohide: true },
          },

          {
            message: "new toast autohide false",
            class: "bg-success",
            options: { autohide: false },
          },
          {
            message: "test toaster 6 danger",
            class: "bg-danger",
            options: { delay: 1500, autohide: true },
          },
          {
            message: "test toaster 7 danger",
            class: "bg-danger bg-opacity-50",
            options: { delay: 1500, autohide: true },
          },
          {
            message: "test toaster 8 danger",
            class: "bg-danger bg-opacity-25",
            options: { delay: 1500, autohide: true },
          },
        ];
      },
    },
  },

  data() {
    return {
      currentToastList: [],
      counter: 0,
    };
  },

  watch: {
    "$page.props.flash": {
      handler() {
        this.parseSessionFlash();
      },
      deep: true,
    },
    "$page.props.errors": {
      handler() {
        this.parseSessionErrors();
      },
      deep: true,
    },
  },

  mounted() {
    // console.log("TOASTCONTAINER MOUNTED");
    this.parseSessionFlash();
    this.parseSessionErrors();
  },

  methods: {
    addToastFromFlash(message = "", cssClass = "", options = {}) {
      this.currentToastList.push({
        time: window.performance.now(),
        message,
        class: cssClass,
        options,
      });
    },

    addToast() {
      let toastIndex = this.counter % this.toastList.length;
      this.currentToastList.push({
        ...{ time: window.performance.now() },
        ...this.toastList[toastIndex],
      });

      this.counter++;
    },

    removeToast(toast) {
      // console.log("removeToast", toast);
      this.currentToastList.splice(this.currentToastList.indexOf(toast), 1);
    },

    parseSessionFlash() {
      // FLASH SUCCESS
      if (this.$page.props?.flash?.success) {
        this.addToastFromFlash(this.$page.props.flash.success, "bg-success", {
          delay: 2500,
          autohide: true,
        });
      }

      // FLASH INFO
      if (this.$page.props?.flash?.info) {
        this.addToastFromFlash(this.$page.props.flash.info, "bg-info", {
          delay: 3500,
          autohide: true,
        });
      }

      // FLASH ERROR
      if (this.$page.props?.flash?.error) {
        this.addToastFromFlash(this.$page.props.flash.error, "bg-danger", {
          autohide: false,
          delay: 5000,
          autohide: true,
        });
      }

      // FLASH MESSAGE
      if (this.$page.props?.flash?.message) {
        this.addToastFromFlash(
          this.$page.props.flash.message,
          "bg-muted text-dark",
          {
            autohide: false,
          }
        );
      }
    },

    parseSessionErrors() {
      // FORM ERRORS
      let lenErrors = Object.keys(this.$page.props?.errors).length;

      if (lenErrors > 0) {
        let message =
          lenErrors === 1
            ? "There is one form error."
            : lenErrors + "  form errors";
        // console.log("toast .$page.props.errors", message);
        this.addToastFromFlash(message, "bg-danger", {
          autohide: false,
        });
      }
    },
  },
};
</script>

<style>
</style>