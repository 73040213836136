<template>
  <pre
    v-if="show || $page?.props?.debug == true"
    class="predebug border-2 rounded-3 px-4 py-2 mb-4 cursor-pointer"
    @click.prevent="isOpen = !isOpen"
    :class="classes"
    :style="{
      'max-height': isOpen ? '100%' : '160px',
      width: 'inherit',
      'font-size': '0.8rem',
      'line-height': '0.92rem',
      'white-space': 'pre-wrap',
      'word-wrap': 'break-word',
      cursor: 'pointer',
    }"
  >
  <h5 class="fw-bold fs-5 mt-0 mb-2" v-show="title">{{ title }}</h5>
  <div class="content" :style="{ 'overflow': 'auto' }">
    <slot />
  </div>
  </pre>
</template>

<script>
export default {
  props: {
    classes: {
      type: String,
      default: "bg-default",
    },
    title: "",
    show: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isOpen: false,
    };
  },
};
</script>

<style>
.bg-default {
  background-color: #b9e2e0;
}
.predebug {
  color: #222;
}

.predebug h5 {
  color: inherit !important;
}
</style>