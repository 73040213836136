<template>
  <div class="form-check form-switch">
    <input class="form-check-input" type="checkbox" id="switchDebug" v-model="debug" />
    <label class="form-check-label text-sm" for="switchDebug">
      <small class="text-muted">{{ __("Debug") }}</small>
    </label>
  </div>
</template>

<script>
export default {
  data() {
    return {
      debug: this.$page?.props?.debug 
    };
  },

  
  watch: {
    debug: {
      handler: function (newVal, oldVal) {
        console.log('watch debug - old:', oldVal, 'new:', newVal)
        this.$inertia.put(
          this.route("admin.settings.debug.toggle"),
          { debug: this.debug },
        );
      },
      immediate: false
    },
  },
};
</script>

<style>
</style>