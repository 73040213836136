<template>
  <div
    ref="toast"
    class="toast show align-items-center mb-3"
    role="alert"
    aria-live="assertive"
    aria-atomic="true"
  >
    <div
      class="toast-body d-flex px-3 py-2 bg-opacity-75"
      :class="toast?.class"
    >
      <!-- <pre class="bg-success bg-opacity-50 mt-1 px-3 py-0">{{ options }}</pre> -->
      <div>
        <slot />
      </div>
      <button
        type="button"
        class="btn-close btn-close-white me-2 m-auto"
        @click.prevent="closeToast"
        aria-label="Close"
      ></button>
    </div>
  </div>
</template>
<script>
export default {
  emits: ["close"],

  props: {
    index: [String, Number],

    toast: {
      type: Object,
      default() {
        return {
          time: 0,
          message: "default",
          class: "bg-info",
          options: {
            animation: false,
            delay: 0,
            autohide: true,
          },
        };
      },
    },
  },

  data() {
    return {};
  },

  mounted() {
    this.initToast();
  },

  methods: {
    initToast() {
      if (this.toast?.options?.autohide == true) {
        let delay = this.toast?.options?.delay || 1000;
        if (delay > 0)
          setTimeout(() => {
            this.removeToast();
          }, delay);
      }
    },

    closeToast() {
      console.count("closeToast via BTN", this.toast?.time);
      this.removeToast();
    },

    removeToast() {
      console.count("removeToast");
      this.$emit("close");
    },
  },
};
</script>
