// console.log("admin here !")


require('./bootstrap');

// Import modules...
import { createApp, h } from 'vue';
import { createInertiaApp, Head, Link } from '@inertiajs/inertia-vue3'
import { InertiaProgress } from '@inertiajs/progress';
import { translations } from '@/mixins/translations';
import { dates } from '@/mixins/dates';
import { files } from '@/mixins/files';
import { errors } from '@/mixins/errors';
import AdminLayout from "@/Layouts/Admin";

// import AdminActions from "@/Layouts/partials/AdminActions";
// import AdminHeader from "@/Layouts/partials/AdminHeader";

import Popper from "vue3-popper";

import Icon from "@/Components/Icon";
import PreDebug from "@/Components/PreDebug";

const el = document.getElementById('app');

if (el) {

    createInertiaApp({
        resolve: async name => {
            console.log(name)
            let page = (await import(`./Pages/${ name }`)).default

            // if (page.layout === undefined) {
            //     page.layout = AdminLayout;
            // }
            return page
        },
        setup({ el, App, props, plugin }) {
            createApp({ render: () => h(App, props) })
                .use(plugin)
                .component('Head', Head)
                .component('InertiaLink', Link)
                .component('AdminLayout', AdminLayout)
                .component('PreDebug', PreDebug)
                .component('Popper', Popper)
                .component('Icon', Icon)
                // .component('AdminHeader', AdminHeader)
                // .component('AdminActions', AdminActions)

                .mixin({ methods: { route: window.route } })
                .mixin(translations)
                .mixin(dates)
                .mixin(files)
                .mixin(errors)
                .mount(el);
        },
        title: title => 'Admin | ' + title
    })

    InertiaProgress.init({ color: '#4B5563', showSpinner: true });
}