<template>
  <div class="overflow-auto px-2">
    <nav>
      <!-- MAIN  -->
      <div class="list-group list-group-flush list-group-sidebar">
        <template v-for="(link, indexLink) in links" :key="indexLink">
          <InertiaLink
            class="list-group-item list-group-item-action"
            :class="{ active: isActiveRoute(link.route) }"
            :href="link.route"
            v-text="__(link.title)"
          />
        </template>
      </div>

      <!--BUSSINESS   -->
      <div class="list-group list-group-flush list-group-sidebar">
        <div class="list-group-item list-group-title">
          <h5 class="fw-bold text-muted text-uppercase">{{ __("Business") }}</h5>
        </div>
        <template v-for="(link, indexLink) in linksBussiness" :key="indexLink">
          <InertiaLink
            class="list-group-item list-group-item-action"
            :class="{ active: isActiveRoute(link.route) }"
            :href="link.route"
            v-text="__(link.title)"
          />
        </template>
      </div>

      <!--CUSTOMIZATION  -->
      <div class="list-group list-group-flush list-group-sidebar">
        <div class="list-group-item list-group-title">
          <h5 class="fw-bold text-muted text-uppercase">{{ __("Customization") }}</h5>
        </div>
        <template v-for="(link, indexLink) in linksCustomization" :key="indexLink">
          <InertiaLink
            class="list-group-item list-group-item-action"
            :class="{ active: isActiveRoute(link.route) }"
            :href="link.route"
            v-text="__(link.title)"
          />
        </template>
      </div>

      <!--ANALYTICS  -->
      <div class="list-group list-group-flush list-group-sidebar">
        <div class="list-group-item list-group-title">
          <h5 class="fw-bold text-muted text-uppercase">{{ __("Analytics") }}</h5>
        </div>
        <template v-for="(link, indexLink) in linksAnalytics" :key="indexLink">
          <InertiaLink
            class="list-group-item list-group-item-action"
            :class="{ active: isActiveRoute(link.route) }"
            :href="link.route"
            v-text="__(link.title)"
          />
        </template>
      </div>

    
    </nav>
  </div>
</template>


<script>
 
export default {
 

  data() {
    return {
      linksBussiness: [

        {
          route: route("admin.formation_sessions.index"),
          title: "Formations Sessions",
        },
        {
          route: route("admin.reservations.index"),
          title: "Reservations",
        },
        {
          route: route("admin.companies.index"),
          title: "Companies",
        },
        {
          route: route("admin.students.index"),
          title: "Students",
        },
      ],

      linksCustomization: [
        {
          route: route("admin.affiliates.index"),
          title: "Affiliates",
        },
        {
          route: route("admin.menus.index"),
          title: "Menus",
        },

        {
          route: route("admin.media_library.index"),
          title: "Media",
        },

        {
          route: route("admin.homepage.show"),
          title: "HomePage",
        },
      ],

      links: [
        {
          route: route("admin.formations.index"),
          title: "Formations",
        },
        {
          route: route("admin.categories.index"),
          title: "Categories",
        },
        {
          route: route("admin.pages.index"),
          title: "Pages",
        },
        {
          route: route("admin.posts.index"),
          title: "Posts",
        },
        {
          route: route("admin.contacts.index"),
          title: "Contacts",
        },
      ],

      linksAnalytics: [
        {
          route: route("admin.users.index"),
          title: "Users",
        },
        {
          route: route("admin.searches.index"),
          title: "Searches",
        },
        {
          route: route("admin.analytics.index"),
          title: "Analytics",
        },
      ]
    };
  },

  methods: {
    isActiveRoute(route) {
      return window.location.href.startsWith(route);
    },
  },
};
</script>


<style scoped>
.list-group-sidebar {
  margin-bottom: 2.5rem;
}

.list-group-sidebar .list-group-item {
  background-color: rgb(36, 32, 32);
  color: #c4c4c6;
  font-weight: bold;
  border: none;
}

.list-group-sidebar .list-group-item a,
.list-group-sidebar .list-group-item button {
  color: #c4c4c6;
  font-weight: bold;
}
.list-group-sidebar .list-group-item.active,
.list-group-sidebar .list-group-item.active a,
.list-group-sidebar .list-group-item.active button {
  color: white;
}

.list-group-sidebar .list-group-item a :hover,
.list-group-sidebar .list-group-item button :hover {
  background-color: rgba(255, 255, 255, 0.4);
  color: white;
  border: 1px solid #f00;
}
</style>