import { DateTime } from "luxon";

export const dates = {
  methods: {

    getDateTime(date) {
      let dateTime;

      dateTime = DateTime.fromJSDate(new Date(date), { zone: "utc" });
      if (dateTime.invalid == null) {
        return dateTime;
      }
      console.error(date, dateTime.invalidReason, dateTime.invalidExplanation);

      dateTime = DateTime.fromISO(date, { setZone: true });
      if (dateTime.invalid == null) {
        return dateTime;
      }
      console.error(date, dateTime.invalidReason, dateTime.invalidExplanation);

      dateTime = DateTime.fromJSDate(date, { setZone: true });
      if (dateTime.invalid == null) {
        return dateTime;
      }
      console.error(date, dateTime.invalidReason, dateTime.invalidExplanation);

      return date;
    },

    dateLabel(date, format = 'DATETIME_MED_WITH_WEEKDAY') {
      let dateTime = this.getDateTime(date);
      // console.log(dateTime);
      if (dateTime?.invalid === null || dateTime?.isValid === true) {
        return dateTime
          .setLocale("fr")
          .setZone("Europe/Paris")
          .toLocaleString(DateTime[format]); //DATETIME_SHORT);
      }
      console.log('unrecognized date format', date)
      return dateTime;
    },


    formattedDateLabel(date) {
      return this.dateLabel(date);
    },

    formattedDateLabelShort(date) {
      return this.dateLabel(date, 'DATETIME_SHORT');
    },

    formattedDateLabelDayShort(date) {
      return this.dateLabel(date, 'DATE_SHORT');
    },



    /* helpers for FlatPicker */
    FP_parseDate(dateString, format) {
      // JS Dates suck. They are always in local time zone (client) or UTC.
      // 		See: https://stackoverflow.com/a/15171030/4002633 
      // They provide no way to express a TZ preference. So we have to keep 
      // note of an expressed preference when parsing and rememeber it.
      // We are only interested in TZ offsets here not in timezones.
      // 		See: https://spin.atomicobject.com/2016/07/06/time-zones-offsets
      // timzones are way-complciate things and a TZ offset is all that is
      // need to create an unambiguous time for database storage. We simply 
      // permit 
      const ustzo =  DateTime.fromFormatExplain(dateString, format)["zone"];
      this.user_specified_offset = ustzo ? ustzo.fixed : undefined;
  
                  // Try parsing the default dateFormat first 
      let date = DateTime.fromFormat(dateString, format);
                  // If that fails walk through the dateVariants to try and find a parse that works
                  let variant = 0;
      while (!date.isValid && variant < this.dateVariants.length) {
        date = DateTime.fromFormat(dateString, this.dateVariants[variant]);
        variant++;
      };
      return date.isValid ? date.toJSDate() : this.last_formatted_date;
    },
    
    FP_formatDate(date, format) {
            // When the date was parsed any user entered TZ offset was stored. We now use that to 
            // format the date that was entered. If none was offered we use the Session preferrred
            // TZ offset.
      const sptzo = "UTC"+SESSION_utcoffset.mins2offset(); // The preferred session timezone
      const ustzo = this.user_specified_offset === undefined ? sptzo : "UTC"+this.user_specified_offset.mins2offset();
      this.last_formatted_date = date;
      return DateTime.fromJSDate(date).setZone(ustzo).toFormat(format);
    },
  }

}

/*

https://github.com/moment/luxon/blob/master/docs/formatting.md

Here's the full set of provided presets using the October 14, 1983 at 13:30:23 as an example.

Name	Description	Example in en_US	Example in fr
DATE_SHORT	short date	10/14/1983	14/10/1983
DATE_MED	abbreviated date	Oct 14, 1983	14 oct. 1983
DATE_FULL	full date	October 14, 1983	14 octobre 1983
DATE_HUGE	full date with weekday	Tuesday, October 14, 1983	vendredi 14 octobre 1983
TIME_SIMPLE	time	1:30 PM	13:30
TIME_WITH_SECONDS	time with seconds	1:30:23 PM	13:30:23
TIME_WITH_SHORT_OFFSET	time with seconds and abbreviated named offset	1:30:23 PM EDT	13:30:23 UTC−4
TIME_WITH_LONG_OFFSET	time with seconds and full named offset	1:30:23 PM Eastern Daylight Time	13:30:23 heure d’été de l’Est
TIME_24_SIMPLE	24-hour time	13:30	13:30
TIME_24_WITH_SECONDS	24-hour time with seconds	13:30:23	13:30:23
TIME_24_WITH_SHORT_OFFSET	24-hour time with seconds and abbreviated named offset	13:30:23 EDT	13:30:23 UTC−4
TIME_24_WITH_LONG_OFFSET	24-hour time with seconds and full named offset	13:30:23 Eastern Daylight Time	13:30:23 heure d’été de l’Est
DATETIME_SHORT	short date & time	10/14/1983, 1:30 PM	14/10/1983 à 13:30
DATETIME_MED	abbreviated date & time	Oct 14, 1983, 1:30 PM	14 oct. 1983 à 13:30
DATETIME_FULL	full date and time with abbreviated named offset	October 14, 1983, 1:30 PM EDT	14 octobre 1983 à 13:30 UTC−4
DATETIME_HUGE	full date and time with weekday and full named offset	Friday, October 14, 1983, 1:30 PM Eastern Daylight Time	vendredi 14 octobre 1983 à 13:30 heure d’été de l’Est
DATETIME_SHORT_WITH_SECONDS	short date & time with seconds	10/14/1983, 1:30:23 PM	14/10/1983 à 13:30:23
DATETIME_MED_WITH_SECONDS	abbreviated date & time with seconds	Oct 14, 1983, 1:30:23 PM	14 oct. 1983 à 13:30:23
DATETIME_FULL_WITH_SECONDS	full date and time with abbreviated named offset with seconds	October 14, 1983, 1:30:23 PM EDT	14 octobre 1983 à 13:30:23 UTC−4
DATETIME_HUGE_WITH_SECONDS	full date and time with weekday and full named offset with seconds	Friday, October 14, 1983, 1:30:23 PM Eastern Daylight Time	vendredi 14 octobre 1983 à 13:30:23 heure d’été de l’Est
*/