<template>
  <nav class="navbar navbar-expand-md navbar-dark bg-dark shadow-sm">
    <div class="container-fluid">
      <InertiaLink class="navbar-brand" :href="route('admin.dashboard')">Admin</InertiaLink>

      <button
        type="button"
        class="btn-dark ms-2 me-auto"
        aria-label="Toggle Sidebar"
        v-on:click="$emit('sidebar-toggle')"
      >
        <span class="bg-success">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-layout-sidebar-inset"
            viewBox="0 0 16 16"
          >
            <path
              d="M14 2a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V3a1 1 0 0 1 1-1h12zM2 1a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2H2z"
            />
            <path d="M3 4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4z" />
          </svg>
        </span>
      </button>

      <button
        class="navbar-toggler btn-sm"
        type="button"
        data-toggle="collapse"
        data-target="#navbarAdmin"
        data-bs-toggle="collapse"
        data-bs-target="#navbarAdmin"
        aria-controls="navbarAdmin"
        aria-expanded="false"
        :aria-label="'Toggle navigation'"
      >
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="navbarAdmin">
        <ul class="navbar-nav ms-auto me-4">
          <!-- <li class="nav-item"></li> -->
          <li class="nav-item dropdown">
            <button
              id="collapseSettings"
              class="btn btn-link nav-link dropdown-toggle"
              role="button"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <Icon name="settings" />
            </button>

            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="collapseSettings">
              <InertiaLink
                class="dropdown-item"
                :href="route('admin.settings.index')"
                v-text="__('Site Settings')"
              />

              <div class="dropdown-item">
                <ShowDebug />
              </div>

              <InertiaLink
                class="dropdown-item"
                :href="route('admin.settings.cache')"
                v-text="__('Cache')"
              />

              <InertiaLink
                class="dropdown-item"
                :href="route('admin.settings.maintenance.show')"
                v-text="__('Maintenance')"
              />
            </div>
          </li>

          <li class="nav-item dropdown">
            <button
              id="navbarDropdownAuth"
              class="btn btn-link nav-link dropdown-toggle"
              role="button"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >{{ $page.props.auth?.user?.name || "User" }}</button>
            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownAuth">
              <a
                class="dropdown-item"
                :href="route('welcome')"
                target="_blank"
              >{{ __("Front Office") }}</a>

              <InertiaLink
                :href="route('logout')"
                class="dropdown-item"
                method="post"
                as="button"
              >{{ __("Log Out") }}</InertiaLink>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>

import ShowDebug from "@/Components/ShowDebug";

export default {

  components: {
    ShowDebug,
  },
  emits: ['sidebar-toggle']
};
</script>

<style>
</style>