<template>
  <Head :title="headTitle" />

  <div v-cloak>
    <NavBar class="fixed-top py-1" @sidebarToggle="sidebarToggle" />

    <div id="main" :class="{ 'sb-sidenav-toggled': !sidebarOpen }">
      <div class="d-flex p-0 m-0" id="wrapper">
        <div class="border-end bg-dark mt-5" id="sidebar-wrapper">
          <SideBar />
        </div>

        <!-- Page content wrapper-->
        <div id="page-content-wrapper">
          <!-- Top navigation-->

          <!-- Page content-->
          <div class="container-fluid overflow-auto mt-5 px-0">
            <div
              class="header-actions py-4 px-5 mb-4 bg-info text-white fw-bold"
            >
              <slot name="header">
                <h2 class="d-flex align-items-center">
                  <span class="text-capitalize">{{ title }}</span>
                  <!-- SHOW HELP ACTION -->
                  <template v-if="$slots.help">
                    <button
                      class="
                        btn btn-lg btn-outline-light
                        rounded-circle
                        ms-auto
                        fw-bolder
                      "
                      :title="__('Aide')"
                      :aria-label="__('Aide')"
                      role="button"
                      @click.prevent="displayHelp = !displayHelp"
                    >
                      ?
                    </button>
                  </template>
                </h2>
              </slot>
            </div>
          </div>

          <FlashMessages />

          <!-- ACTIONS SLOT -->
          <div class="container-fluid" v-if="$slots.actions">
            <div class="card mb-4">
              <div class="card-body">
                <slot name="actions" />
              </div>
            </div>
          </div>

          <!-- HELP SLOT -->
          <div class="container-fluid" v-if="$slots.help">
            <div class="alert alert-info mb-4" v-show="displayHelp">
              <slot name="help" />
            </div>
          </div>

          <!-- MAIN -->
          <div class="container-fluid">
            <slot />
          </div>
        </div>
      </div>
    </div>

    <ToastContainer :test="false" />
  </div>
</template>

<script>
import NavBar from "@/Layouts/partials/NavBar.vue";
import SideBar from "@/Layouts/partials/SideBar.vue";
import FlashMessages from "@/Components/FlashMessages.vue";
import ToastContainer from "@/Components/Toasts/ToastContainer.vue";

export default {
  components: {
    NavBar,
    SideBar,
    FlashMessages,
    ToastContainer,
  },

  props: {
    title: {
      type: String,
      default: "Admin Groupe Vicra",
    },

    showHelp: {
      type: [Boolean],
      default: true,
    },
  },

  data() {
    return {
      sidebarOpen: true,
      displayHelp:
        !!this.$page?.props?.site_settings?.admin?.show_help ||
        this.$page?.props?.site_settings?.admin?.show_help == 1 ||
        this.showHelp,
    };
  },
  computed: {
    headTitle() {
      return this.title || "Admin ☺";
    },
  },

  methods: {
    sidebarToggle() {
      this.sidebarOpen = !this.sidebarOpen;
    },
  },
};
</script>

<style lang="scss">
/*
  Enter and leave animations can use different
  durations and timing functions.
*/
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.5s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(200px);
  opacity: 0;
}

#wrapper {
  overflow-x: hidden;
}

#sidebar-wrapper {
  min-height: 100vh;
  margin-left: -15.5rem;
  transition: margin 0.25s ease-out;
}

#sidebar-wrapper .sidebar-heading {
  padding: 0.875rem 1.25rem;
  font-size: 1.2rem;
}

#sidebar-wrapper .list-group {
  width: 15rem;
}

#page-content-wrapper {
  min-width: 100vw;
}

.sb-sidenav-toggled #wrapper #sidebar-wrapper {
  margin-left: 0;
}

@media (min-width: 768px) {
  #sidebar-wrapper {
    margin-left: 0;
  }

  #page-content-wrapper {
    min-width: 0;
    width: 100%;
  }

  .sb-sidenav-toggled #wrapper #sidebar-wrapper {
    margin-left: -15rem;
  }
}
</style>